
.scoreContainer {
    display: flex;
    justify-content: center;
    margin-top: 75px;
    @media (max-width: 1100px): {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 15px;
    }
  }
  
  
  .textBox {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 1100px): {
        flexDirection: column;
        height: 145vh;
        display: none;
      }
    }
  
    .color {
      height: 95px;
      width: 95px;
      margin: 2px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      text-align: center;
    }